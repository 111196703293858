import React, { useEffect, useState } from 'react'
import * as Sentry from '@sentry/react'

const SentryFeedback = () => {
  // State
  const [feedback, setFeedback] = useState()

  useEffect(() => {
    setFeedback(Sentry.getFeedback())
  }, [])

  useEffect(() => {
    if (feedback) {
      feedback.attachTo(document.querySelector('#feedback-button'), {
        formTitle: 'Share Feedback',
        messagePlaceholder: 'How can we improve?',
        submitButtonLabel: 'Send',
        useSentryUser: {
          name: 'name',
          email: 'email',
        },
        successMessageText:
          'Thank you for your feedback! If you need immediate assistance, please contact us at warp.help@warrantyprocessing.com.',
      })
    }
  }, [feedback])

  return (
    <button
      id="feedback-button"
      type="button"
      className="fixed bottom-4 left-4 z-10 cursor-pointer rounded-full bg-white px-4 py-3 shadow-md hover:bg-gray-100 hover:shadow-lg"
    >
      📣 Feedback
    </button>
  )
}

export default SentryFeedback
